import { DealSheet } from '@digital-motors-boatyard/common/dist/interfaces';
import { STATUS_SUCCESS } from '@digital-motors-boatyard/common-frontend/dist/constants';
import omit from 'lodash/omit';

import { createDealSheet } from '../../../api/createDealSheet';
import { getDealSheet } from '../../../api/getDealSheet';

export const getOrCreateDealSheet = async (
  dealSheet: Partial<DealSheet>
): Promise<DealSheet | null> => {
  if (dealSheet.id) {
    const dealSheetRes = await getDealSheet(dealSheet.id);

    return dealSheetRes.type === STATUS_SUCCESS ? dealSheetRes.value : null;
  }

  const newDealSheet = omit(dealSheet, 'id');
  const createRes = await createDealSheet(newDealSheet);

  if (createRes.type === STATUS_SUCCESS && createRes.value.id) {
    return createRes.value;
  }
  return null;
};
